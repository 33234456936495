import * as React from "react";
const SvgFace = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="face_svg__Layer_2"
    data-name="Layer 2"
    viewBox="0 0 611.37 726"
    {...props}
  >
    <defs>
      <style>
        {
          ".face_svg__cls-1{fill:none;stroke:#000;stroke-miterlimit:10;stroke-width:1.2px}"
        }
      </style>
    </defs>
    <path
      d="m400.76 81.36-8.78-26.34s.8-7.18-7.98-13.57l-11.7-8.56s-10.98-3.57-20.04-3.57-34.04 1.1-47.49 4.12-98.55 24.98-98.55 24.98-20.31 8.24-30.47 17.84-13.45 17.57-13.45 17.57-8.24 16.2-11.26 20.86-15.65 19.49-18.94 26.63-3.02 12.63-4.94 17.29-6.86 14.55-6.86 14.55c-1.37 13.73-3.02 27.73-3.02 27.73v30.47l2.75 40.63-.81 18.33c0 .08.1.11.14.04l2.04-4.09s1.1-1.65 4.67-.82 6.86 14.55 6.04 10.16 4.12 20.04 4.12 20.04l.27 13.73s1.1 6.59 4.39 12.63 9.61 19.22 9.61 19.22l4.94 40.08 5.22 26.08 4.39 9.88 2.47 19.77 2.2 7.41s3.02 11.26 6.31 17.02 26.63 33.22 26.63 33.22l47.22 31.3 38.43 25.26s10.43 3.02 21.14 2.75 17.57.27 25.81-1.1 23.06-9.06 27.45-12.35c4.39-3.29 26.08-21.96 31.84-28.82 5.76-6.86 26.08-33.49 29.37-37.88s9.33-10.43 9.33-10.43 5.76-15.92 7.69-20.59 7.14-19.77 8.24-27.18 4.39-29.65 4.94-37.34 1.37-39.26 1.37-39.26l1.65-38.98-1.65-49.14s3.02.55 4.94 7.41 1.92 23.61 1.37 31.57-.82 20.31-.82 20.31 7.41-11.8 9.06-18.67c1.65-6.86 4.39-26.35 4.39-33.77s-3.84-42.28-3.29-51.06l.55-8.78s2.75-17.57 2.75-26.35-2.75-19.77-4.39-22.24-52.16-60.94-52.16-60.94 1.1-9.33-4.94-18.94-12.17-8.08-12.17-8.08Z"
      className="face_svg__cls-1"
      strokeDasharray="1600"
      strokeDashoffset="1600"
    />
    <path
      d="M184.96 210.04s-8.02 1.46-13.86 0-13.86-3.65-13.86-3.65l-5.11-7.3s-3.65 28.45-3.65 45.23-2.92 61.28 0 78.79 17.51 48.15 23.34 56.17 40.12 45.96 40.12 45.96v-29.25s8.02-14.52 15.32-19.63 61.28-13.37 61.28-13.37 5.84-1.7 10.94 0 15.32 8.27 15.32 8.27l17.51-8.27s1.46-4.14 6.57-4.86 30.64 8.02 30.64 8.02 26.26 9.48 29.91 13.13 10.94 12.4 10.94 21.89 37.21-34.29 37.21-34.29l5.84-36.48v-28.76l-8.02-25.95-.73-38.66-4.38-6.57v-36.48l-14.59-28.45-38.66-58.36s-5.84-5.11-10.94-4.38-26.26 10.94-40.85 12.4-49.61 2.19-49.61 2.19-29.91 14.59-40.12 29.18-21.89 37.21-32.83 45.96-27.72 17.51-27.72 17.51ZM121.78 301.65l-2.59-10.99 5.3 28.31 3.87 24.37s4.26 16.25 9.29 23.6 15.58 16.53 15.58 16.53M460.16 334.08l.81-3.89-5.22 22.42"
      className="face_svg__cls-1"
      strokeDasharray="1124"
      strokeDashoffset="1124"
    />
    <path
      d="M274.27 237.22c-2.82-2.11-15.15-4.58-20.44-5.28s-31.71-3.17-31.71-3.17-13.39-1.41-20.08 0-10.22 4.23-12.68 8.46-4.23 9.51-4.23 9.51h19.03s6.69-2.82 13.74-2.47 30.65 6.34 35.94 7.05 18.32 0 18.32 0c3.17-4.23 2.11-14.09 2.11-14.09ZM345.44 242.38c0-5.94 5.28-12.54 5.28-12.54 5.94-1.65 2.97-2.09 22.1-1.71 19.13.39 29.69-.93 36.62 2.04s16.49 11.55 19.79 15.17 0 4.95 0 4.95l-16.49-1.98s-10.89-3.96-18.8-3.3-28.04 2.97-28.04 2.97-8.25 2.64-12.21 2.31-8.25-7.92-8.25-7.92Z"
      className="face_svg__cls-1"
      strokeDasharray="210"
      strokeDashoffset="210"
      fill="none"
    />
  </svg>
);
export default SvgFace;

